import { Grid } from "@mui/material";
import { graphql, HeadFC, PageProps } from "gatsby";
import * as React from "react";
import { MainFrame } from "../components/MainFrame";
import { Pager } from "../components/Pager";
import { PostCard } from "../components/PostCard";
import { postsParPage } from "../consts/values";
import "../styles/styles.scss";

const IndexPage = ({ data }: PageProps<Queries.ListPostsQuery>) => {
  const totalPages = Math.ceil(
    (data?.allMicrocmsBlogs?.totalCount || 0) / postsParPage
  );

  const content = data?.allMicrocmsBlogs?.nodes
    ?.slice(0, postsParPage)
    .map((value, index) => {
      return (
        <PostCard
          key={`post-${index}`}
          blogsId={value.blogsId}
          eyecatch={value.eyecatch}
          title={value.title}
          content={value.content}
          tags={value.tags}
          publishedAt={value.publishedAt}
          forceView={index < 6}
        />
      );
    });

  return (
    <MainFrame type="website">
      <Grid container spacing={2}>
        {content}
      </Grid>
      <Grid container>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <Pager archiveName="pages" currentPage={1} totalPages={totalPages} />
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </MainFrame>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <></>;

export const query = graphql`
  query ListPosts {
    allMicrocmsBlogs(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        blogsId
        content {
          richEditor
          html
        }
        eyecatch {
          url
        }
        title
        tags {
          id
          name
        }
        publishedAt
      }
      totalCount
    }
  }
`;
